import { Plan } from 'src/types/Plan';
import TagManager from 'react-gtm-module';
import { useSubscription } from '@stores/subscription';
import { useUser } from '@stores/user';
import { getAbTestsString } from '@statsig/abTests';

export type GA4TrackEventProps = {
  action?: string;
  account_type?: string;
  sub_type?: Plan['id'];
  payment_type?: 'credit_card' | '';
  price_amount?: string;
  lts_segment?: string;
  login_type?: string;
  current_term?: string;
  intended_term?: string;
  product_field?: string;
};

export type GA4TrackModuleProps = {
  index?: number;
  click_text?: string;
  click_url?: string;
  click_path?: string;
};

type GAUniversalTrackBaseProps = {
  event?: string;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
  element_name?: string;
};

export type GA4TrackProps = GAUniversalTrackBaseProps & GA4TrackEventProps & GA4TrackModuleProps;

const log = {
  track: (props: GA4TrackProps) => {
    const data = {
      event: 'membership',
      ...props,
    };
    TagManager.dataLayer({ dataLayer: data });
  },
  initAndPushIds: () => {
    const user = useUser.getState().user;
    const subscription = useSubscription.getState().subscription;

    const insiderId = user?.memberId || '';
    const subscriptionId = subscription?.id || '';
    const universalId = localStorage.getItem('universal_id') || '';
    const userPhase = user?.hasPrime === 1 ? 'subscriber' : 'account';

    const ab_forseti = getAbTestsString();
    const data = {
      insiderId,
      subscriptionId,
      universalId,
      ab_forseti,
      userPhase,
    };

    TagManager.initialize({
      gtmId: 'GTM-MP6F46L',
      dataLayer: data,
      events: { 'gtm.start': new Date().getTime(), event: 'gtm.js' },
    });
  },
};

export default log;
