import { currencySymbol } from '@utils/price';
import { getInvoices } from '../requests';
import { useEffect, useState } from 'react';
import { format, parseISO, differenceInDays } from 'date-fns';
import { Currency } from 'src/config/plans/types';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import Button from '@components/button';
import { leftChevron } from '@assets/common';
import { toast } from '@components/toast/Toast';
import { trackSubPageEvent } from '../analytics';

interface Invoice {
  attributes: {
    createdAt: string;
    amountPaid: string;
    pdfUrl: string;
    currency: Currency;
  };
  id: string;
}

const BillingHistory = () => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(12);
  const [showLoadMoreBtn, setShowLoadMoreBtn] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchInvoices() {
      try {
        const data = await getInvoices({ offset, limit });
        if (data.length) {
          setInvoices((invoices) => invoices.concat(data));
        } else {
          setShowLoadMoreBtn(false);
          toast.notice('This is your entire billing history');
        }
      } catch (err) {
        console.error(err);
        toast.error('There has been an error loading your billing history');
      }
    }
    fetchInvoices();
  }, [limit, offset]);

  const onLoadMore = async () => {
    trackSubPageEvent('billing_history_load_more');
    const newOffset = limit;
    const newLimit = limit + 12;

    // updating offset + limit state causes useEffect to refetch
    setOffset(newOffset);
    setLimit(newLimit);
  };

  const onClickView = (pdfUrl: Invoice['attributes']['pdfUrl']) => {
    trackSubPageEvent('billing_history_view');
    window.open(pdfUrl, '_blank');
  };

  return (
    <section className='billing-history'>
      <div className='billing-history-back' onClick={() => navigate('/account/subscription')}>
        <img src={leftChevron} /> <h1>Billing History</h1>
      </div>

      <table>
        <thead>
          <tr>
            <th className='date-column'>Date</th>
            <th className='amount-column'>Amount</th>
            <th className='receipts-column'>Receipts</th>
          </tr>
        </thead>
        <tbody>
          {invoices.length
            ? invoices.map((invoice: Invoice) => {
              const { createdAt, amountPaid, currency, pdfUrl } = invoice.attributes;
              const parsedDate = parseISO(createdAt);
              const daysSinceCreated = differenceInDays(new Date(), parsedDate);
              
              return (
                <tr key={invoice.id}>
                  <td className='date-column'>{format(parsedDate, 'MM/dd/yy')}</td>
                  <td className='amount-column'>
                    {currencySymbol(currency)}
                    {amountPaid}
                  </td>
                  <td className='receipts-column'>
                    {daysSinceCreated <= 30 ? (
                      <a
                        href={pdfUrl}
                        onClick={() => onClickView(pdfUrl)}
                        target='_blank'
                        rel='noreferrer'
                      >
                          View
                      </a>
                    ) : (
                      'n/a'
                    )}
                  </td>
                </tr>
              );
            })
            : null}
        </tbody>
      </table>

      {showLoadMoreBtn && (
        <Button color='white' type='button' onClick={async () => await onLoadMore()}>
          Load more
        </Button>
      )}

      <div className='subscription__questions consumer'>
        Have any questions? Contact{' '}
        <a
          href='mailto:subscriptions@businessinsider.com?subject=Consumer Subscription'
          target='_blank'
          rel='noreferrer'>
          subscriptions@businessinsider.com
        </a>{' '}
      </div>
    </section>
  );
};

export default BillingHistory;
