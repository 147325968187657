import {
  mySubscriptionForCorpAdmin,
  mySubscriptionForCorpMember,
  mySubscriptionForConsumer,
  upgradeSubscription,
  cancelSubscription,
  answerCancelSubscriptionSurvey,
  getSubscriptionHistories,
  restoreSubscription,
} from '../screens/account/subscription/requests';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { getErrorMessage } from '@utils/validation';
import { useUser } from './user';
import { useEnterprise } from './enterprise';
import { getAbTestsObject } from '@statsig/abTests';
import type { SubscriptionType, SubscriptionHistory } from '../types/subscription';

type OperationStatus = {
  result: boolean;
  message?: string;
};

interface UpdateSubscriptionArgs<K extends keyof SubscriptionType> {
  key: K;
  value: SubscriptionType[K];
}

interface SubscriptionState {
  subscription?: SubscriptionType;
  subscriptionHistories?: SubscriptionHistory[];
  isLoading: boolean;
  load: () => Promise<void>;
  update: (args: UpdateSubscriptionArgs<keyof SubscriptionType>) => void;
  upgrade: (planId: string) => Promise<OperationStatus>;
  answerCancelationSurvey: (feedback: {
    reason: string;
    feedback: string;
  }) => Promise<OperationStatus>;
  cancel: () => Promise<OperationStatus>;
  loadSubscriptionHistories: () => Promise<void>;
  restore: () => Promise<OperationStatus>;

  shouldShowBigPlanCard: boolean;
  setShouldShowBigPlanCard: (value: boolean) => void;
}

type GetType = () => SubscriptionState;

type SetType = (state: Partial<SubscriptionState>) => void;

type SubscriptionRequestArgs = {
  subscriptionReq: (subscriptionId: string) => Promise<SubscriptionType>;
  get: GetType;
  set?: SetType;
};

/**
 * Abstraction for subscription functions such as upgrade, cancel, restore, etc.
 * Needed because each function returns a `SubscriptionType` object that needs to be updated in this store.
 *
 * @param args.subscriptionReq Function that receives a Sigyn subscription uuid returns a promise that resolves to a `SubscriptionType` object.
 * @param args.get store `get` function
 * @param args.set store `set` function
 */
const doSubscriptionRequest = async (args: SubscriptionRequestArgs): Promise<OperationStatus> => {
  const { subscriptionReq, get, set } = args;
  const subscription = get().subscription;
  if (!subscription || !subscription.id) {
    return new Promise((resolver) =>
      resolver({ result: false, message: 'Subscription does not exists.' })
    );
  }

  try {
    const returnedSubscription = await subscriptionReq(subscription.id);

    if (set)
      set({
        subscription: {
          ...subscription,
          ...(returnedSubscription as unknown as SubscriptionType),
        },
        isLoading: false,
      });
    return { result: true };
  } catch (ex) {
    console.error(ex);
    return { result: false, message: getErrorMessage(ex) };
  }
};

export const useSubscription = create<SubscriptionState>()(
  persist(
    (set, get) => ({
      subscription: undefined,
      paymentMethod: undefined,
      subscriptionHistories: undefined,
      isLoading: true,
      answerCancelationSurvey: async (survey: { reason: string; feedback: string }) => {
        try {
          await answerCancelSubscriptionSurvey(survey);
          return { result: true };
        } catch (ex) {
          return { result: false, message: getErrorMessage(ex) };
        }
      },
      upgrade: (planId) =>
        doSubscriptionRequest({
          subscriptionReq: (id) => upgradeSubscription(id, { planId, abTests: getAbTestsObject() }),
          get,
          set,
        }),
      cancel: () =>
        doSubscriptionRequest({
          subscriptionReq: (id) => cancelSubscription(id, { abTests: getAbTestsObject() }),
          get,
          set,
        }),
      restore: () =>
        doSubscriptionRequest({
          subscriptionReq: (id: string) => restoreSubscription(id),
          get,
          set,
        }),
      load: async () => {
        const user = useUser.getState().user;
        if (!user) {
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const isAdmin = (useEnterprise.getState() as any).userIsAdmin;
        const isEnterprise = !!(user.enterprise || user.enterpriseId);

        /* User can be consumer, enterprise admin or enterprise member. */
        // eslint-disable-next-line no-nested-ternary -- fix in issue #342
        const getDataFn = !isEnterprise
          ? mySubscriptionForConsumer
          : isAdmin
            ? mySubscriptionForCorpAdmin
            : mySubscriptionForCorpMember;

        try {
          set({ isLoading: true });
          const subscription = await getDataFn(user);
          /* TODO: for now we force to SubscriptionType but we may need to create a unified state (can be one or other) */
          set({ subscription: subscription as SubscriptionType, isLoading: false });
        } catch (ex) {
          console.error(ex);
          set({ isLoading: false });
        }
      },
      loadSubscriptionHistories: async () => {
        try {
          const subscriptionHistories = await getSubscriptionHistories();
          set({ subscriptionHistories });
        } catch (error) {
          console.error(error);
        }
      },
      update: (args) => {
        set((state) => {
          if (!state.subscription) return state;
          return { subscription: { ...state.subscription, [args.key]: args.value } };
        });
      },

      shouldShowBigPlanCard: true,
      setShouldShowBigPlanCard: (shouldShowBigPlanCard) => {
        set({ shouldShowBigPlanCard });
      },
    }),
    { name: 'subscription', version: 2 }
  )
);
