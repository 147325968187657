import { usePlans } from '@stores/plans';
import { useSubscription } from '@stores/subscription';
import { useMemo } from 'react';
import './style.scss';
import PlanCard from '../PlanCard/PlanCard';
import BigPlanCard from '../BigPlanCard/BigPlanCard';
import { PlanCode } from '../../../../config/plans/types';

const UpgradeSection = (): JSX.Element => {
  const plans = usePlans((state) => state.plans);
  const subscription = useSubscription((state) => state.subscription);
  const shouldShowBigPlanCard = useSubscription((state) => state.shouldShowBigPlanCard);

  const plansToShowBasedOnActiveSub = useMemo(() => {
    // The 'Switch' strategy only calls for the Annual option for active Monthly subscribers right now (MR-1170)
    if (!subscription) return;
    if (!plans.length) return;

    const { currency } = subscription.plan;
    const upgradePlans = plans.filter((plan) => plan.config?.planCode === PlanCode.UPGRADE);

    const annualPlan = upgradePlans.find(
      (plan) => plan.interval === 'yearly' && !plan.metadata?.adFree && plan.currency === currency
    );

    // const annualAdFreePlan = upgradePlans.find(
    //   (plan) => plan.interval === 'yearly' && plan.metadata?.adFree && plan.currency === currency
    // );

    if (!annualPlan) throw new Error('Cannot find Annual plan from state');
    // if (!annualAdFreePlan) throw new Error('Cannot find Annual Ad-free plan from state');

    const annualPlanCard = shouldShowBigPlanCard ? (
      <BigPlanCard plan={annualPlan} />
    ) : (
      <PlanCard plan={annualPlan} />
    );

    if (subscription.cadence === 'monthly') {
      return annualPlanCard;
    }

    // if (subscription.cadence === 'Annual') {
    //   return (
    //     <>
    //       <PlanCard plan={annualAdFreePlan} />
    //     </>
    //   );
    // }
  }, [plans, subscription, shouldShowBigPlanCard]);

  return (
    <section data-testid='subs-upgrade-section' className='upgrade__section'>
      <h2 className='upgrade__title'>Switch and save</h2>
      {plansToShowBasedOnActiveSub}
    </section>
  );
};

export default UpgradeSection;
